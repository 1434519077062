import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {CartItem} from './CartItem/CartItem';

export enum CartItemsDataHook {
  'root' = 'CartItemsDataHook.root',
  'item' = 'CartItemsDataHook.item',
}

export function CartItems() {
  const {cart, isPreOrderEnabled} = useControllerProps().cartStore;

  return (
    <div data-hook={CartItemsDataHook.root}>
      <ul>
        {cart.items.map((item) => (
          <li key={`cart-item-${item.cartItemId}`} data-hook={CartItemsDataHook.item}>
            <CartItem isSupportOnlyTitleOptionEnabled={isPreOrderEnabled} item={item} />
          </li>
        ))}
      </ul>
    </div>
  );
}
